/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
// import dashboard from './dashboard'
// import appsAndPages from './apps-and-pages'
// import others from './others'
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'

// Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
export default [
    // { title: 'Users Management', icon: 'UserIcon', route: 'user-list' },
    // { title: 'Register', icon: 'LockIcon', route: 'auth-register', action: 'read', resource: 'Auth' },
    // { title: 'Forgot Password', icon: 'LockIcon', route: 'auth-forgot-password', action: 'read', resource: 'Auth' },
    // { title: 'Reset Password', icon: 'LockIcon', route: 'auth-reset-password', action: 'read', resource: 'Auth' },
    {
        title: 'Home',
        icon: 'HomeIcon',
        children: [
            {
                title: 'Banner',
                route: 'home-banner-list',
                action: 'read',
                resource: 'Auth',
            },
            {
                title: 'Testimonial',
                route: 'home-testimonial-list',
                action: 'read',
                resource: 'Auth',
            },
            {
                title: 'Page Setting',
                route: 'home-setting',
                action: 'read',
                resource: 'Auth',
            },
        ],
    },
    {
        title: 'Team',
        icon: 'UserIcon',
        children: [
            {
                title: 'Member',
                route: 'member-list',
                action: 'read',
                resource: 'Auth',
            },
            {
                title: 'Counter Bar',
                route: 'member-counter-bar',
                action: 'read',
                resource: 'Auth',
            },
        ],
    },
    {
        title: 'About',
        icon: 'AlertCircleIcon',
        children: [
            {
                title: 'Banner',
                route: 'about-banner',
                action: 'read',
                resource: 'Auth',
            },
            {
                title: 'Who we are',
                route: 'about-whoweare',
                action: 'read',
                resource: 'Auth',
            },
            {
                title: 'What we do',
                route: 'about-whatwedo',
                action: 'read',
                resource: 'Auth',
            },
            {
                title: 'Vision/Mission',
                route: 'about-mission',
                action: 'read',
                resource: 'Auth',
            },
            {
                title: 'Gallery',
                route: 'about-gallery',
                action: 'read',
                resource: 'Auth',
            },
            {
                title: 'Page Setting',
                route: 'about-setting',
                action: 'read',
                resource: 'Auth',
            },
        ],
    },
    {
        title: 'Business Line',
        icon: 'BriefcaseIcon',
        children: [
            {
                title: 'Restaurants & Bars',
                route: 'restaurants',
                action: 'read',
                resource: 'Auth',
            },
            {
                title: 'Nightclubs',
                route: 'nightclubs',
                action: 'read',
                resource: 'Auth',
            },
            {
                title: 'Hotels',
                route: 'hotels',
                action: 'read',
                resource: 'Auth',
            },
        ],
    },
    {
        title: 'Contact us',
        icon: 'MailIcon',
        route: 'contact-us',
        action: 'read',
        resource: 'Auth'
    }
]
