<template>
  <b-nav-item-dropdown
    v-if="userData"
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <b-img
        v-if="userData.avatarUrl !== 'null'"
        :src="userData.avatarUrl"
        width="40px"
        height="40px"
        class="rounded-full"
      />
      <b-avatar
        v-else
        size="40"
        :text="avatarText(userData.firstname)"
        :variant="`light-${resolveUserRoleVariant(userData.role)}`"
      />
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ `${userData.firstname} ${userData.lastname}` }}
        </p>
        <span class="user-status">{{ getRoleName(userData.role) }}</span>
      </div>
    </template>

    <b-dropdown-item
      v-if="userData.email === 'admin@akarahospitality.com'"
      :to="{ name: 'user-list' }"
      link-class="d-flex align-items-center"
    >
      <span>Users Management</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'user-profile' }"
      link-class="d-flex align-items-center"
    >
      <span>Profile Setting</span>
    </b-dropdown-item>
    <b-dropdown-divider />
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BImg
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BImg,
    BAvatar,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    resolveUserRoleVariant(role) {
      if (role === 'subscriber') return 'primary'
      if (role === 'author') return 'warning'
      if (role === 'maintainer') return 'success'
      if (role === 'editor') return 'info'
      if (role === 'admin') return 'danger'
      return 'primary'
    },
    getRoleName(role) {
      if (role === 'admin')
        return 'Adminstrator';

      return 'Client';
    },
    logout() {
      this.$swal({
        title: 'Are you sure to logout?',
        icon: 'warning',
        iconColor: '#FF9F43',
        showCancelButton: true,
        confirmButtonText: 'Logout',
        customClass: {
          htmlContainer: 'my-50',
          actions: 'flex-row-reverse my-1',
          confirmButton: 'btn btn-primary px-5',
          cancelButton: 'btn btn-outline-secondary px-5 mr-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (!result.value) return

        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove userData from localStorage
        localStorage.removeItem('userData')

        // Reset ability
        this.$ability.update(initialAbility)

        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.user-status {
  margin-left: 3px;
}
</style>